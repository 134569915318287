import { Stack, Typography } from '@mui/material';
import Image from 'next/image';
import React from 'react';

export default function ThreeColumnComponent() {
  const data = [
    {
      key: 1,
      label: 'Improved productivity',
      desc: '70% of your expense are manpower costs. Put nature at work to grow productivity by 11%.',
      img: '/assets/images/happy_people.svg',
    },
    {
      key: 2,
      label: 'Enhanced well-being',
      desc: 'Happy teams create better products. Use data-backed solution to foster well-being.',
      img: '/assets/images/healthy_savings.svg',
    },
    {
      key: 3,
      label: 'Healthy community',
      desc: 'Urban greening up is crucial for sustainability. Get the actions, metrics at your fingertips.',
      img: '/assets/images/better_planet.svg',
    },
  ];

  return (
    <Stack container gap={1.875} direction={{ xs: 'column', md: 'row' }}>
      {data.map((item) => (
        <Stack
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            py: 5,
            px: 3,
          }}
          key={item.key}
          gap={5}
          alignItems="flex-start"
        >
          <Image height={96} width={96} src={item.img} alt={item.label} />

          <Stack gap={1} mb={2}>
            <Typography variant={'h5'} fontWeight={700}>
              {item.label}
            </Typography>

            <Typography
              variant="body1"
              color="text.primary"
              fontFamily={'Inter'}
            >
              {item.desc}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
