import React, { useEffect } from "react";
import { Box, Tabs, Tab, Stack, useTheme, useMediaQuery } from "@mui/material";
import Image from "next/image";

const companyData = [
  {
    key: "loreal",
    image: "/assets/images/plant_bundles/loreal.png",
    label: "Fortune 500",
  },
  {
    key: "inmobi",
    image: "/assets/images/plant_bundles/inmobi.png",
    label: "Technology",
  },
  {
    key: "sm",
    image: "/assets/images/plant_bundles/sm.png",
    label: "Developers",
  },
  {
    key: "hospitality",
    image: "/assets/images/plant_bundles/hospitality.png",
    label: "Hospitality",
  },
  {
    key: "gbb",
    image: "/assets/images/plant_bundles/gbb.png",
    label: "Parks",
  },
];

export default function TabsTopContentComponent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = React.useState(0);

  // Set the delay time in milliseconds
  const delay = 2000; // Adjust as needed
  const totalTabs = 5;

  useEffect(() => {
    let currentTab = 0;

    const interval = setInterval(() => {
      setValue(currentTab);
      currentTab++;

      if (currentTab === totalTabs) {
        // clearInterval(interval); // Stop the interval when all tabs are selected
        currentTab = 0;
      }
    }, delay);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [totalTabs, delay]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack gap={8.75} alignItems="center">
      <Image
        height={160}
        width={290}
        src={companyData[value].image}
        alt={companyData[value].key}
      />

      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={{ xs: "scrollable", md: "fullWidth" }}
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: "600",
            textDecoration: "none",
            color: "primary.purple",
            opacity: 1,
          }}
          centered={!isMobile}
        >
          {companyData.map((item, index) => (
            <Tab label={item.label} value={index} key={item.key} />
          ))}
        </Tabs>
      </Box>
    </Stack>
  );
}
