import * as React from "react";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ progress }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E6E6E6",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: progress > 0 ? "#F44174" : "#E6E6E6",
  },
  padding: "0 .5rem",
}));

export default function LinearDeterminate({
  inProgress,
  delay,
  children,
  active,
}) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    let timer;
    if (active && !inProgress) {
      setProgress(100);
      return;
    }

    if (inProgress) {
      setProgress(0);
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, delay / 30);
    } else {
      const timer2 = setInterval(() => {
        clearInterval(timer);
      }, 30);
      clearInterval(timer2);
      setProgress(0);
    }

    return () => {
      clearInterval(timer);
      setProgress(0);
    };
  }, [inProgress, delay, active]);

  return (
    <Stack height="100%" gap={2.5}>
      <BorderLinearProgress
        variant="determinate"
        value={progress}
        progress={progress}
      />
      <Typography
        sx={{
          fontSize: {
            xs: "0.75rem",
            md: "1rem",
          },
        }}
        fontFamily={"Inter"}
      >
        {children}
      </Typography>
    </Stack>
  );
}
