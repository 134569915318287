import HomeComponentContainer from '@components/Home/NewHome/HomeComponentContainer';
import PillWrapper from '@components/Home/NewHome/PillWrapper';
import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React from 'react';

const sliderItems = [
  {
    review:
      'naturethings is a remarkable startup that shares our vision of transforming urban workspaces with a touch of nature. Their solution addresses the problem of high cost of acquiring and maintaining live plants by combining nature, AI-technology to create a sustainable spaces.',
    reviewBy: 'Gaurav Joshi',
    on: 'Co-Founder - WorkBoulevard',
    pic: '/assets/images/plant_bundles/Testimonial_1.png',
  },
  {
    review:
      'Our office looks amazing and lively. naturethings has made it super easy for me to do that. The Maintenance and employee engagement is innovative. Very happy we subscribed.',
    reviewBy: 'Rabi G',
    on: 'Global Facilities Lead - InMobi',
    pic: '/assets/images/plant_bundles/Testimonial_1.png',
  },
  {
    review:
      'Trendy, smart and so affordable. I love plants but never had the time to figure out my office plants. naturethings made it incredibly effortless to match, bring in some gorgeous greens to our office. My team LOVES how lively and fresh our space feels.',
    reviewBy: 'Megan C',
    on: 'Founder - UGP',
    pic: '/assets/images/plant_bundles/Testimonial_2.png',
  },
  {
    review:
      'The nature things chat bot was a great experience and helpful in maintenance, which is otherwise neglected...Very good job. Will recommend to our clients.',
    reviewBy: 'Praneetha M',
    on: 'Sustainability Lead - Space Matrix',
    pic: '/assets/images/plant_bundles/Testimonial_3.jpeg',
  },
  {
    review:
      'Impressive. I have recommended the app to tenants and also house plants lovers to help them track the maintenance routine and plant health.',
    reviewBy: 'LiangFa L',
    on: 'Dy. Director - Gardens By the Bay',
    pic: '/assets/images/plant_bundles/Testimonial_4.jpg',
  },
  {
    review:
      'Super quick delivery and healthy plants are great but I really love the AI Chat Bot for the maintenance support. Really innovative and user friendly. Makes it easy to identify any issues with my plants.',
    reviewBy: 'Bjorn',
    on: 'Founder - EGC',
    pic: '/assets/images/plant_bundles/Testimonial_4.png',
  },
];

const Carousel = dynamic(() => import('react-material-ui-carousel'), {
  ssr: false,
});

const CarouselCard = styled.div`
  padding: 1rem 0px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-image: url('/assets/images/quotes.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 35px 40px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: 1rem 1rem 3rem 1rem;
    border-radius: 24px;
    margin: 0 5rem;
    background-image: url('/assets/images/quotes.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35px 40px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 0 10rem;
  }
`;

const NavButton = styled(Image)`
  cursor: pointer;
`;

function Item(props) {
  return (
    <CarouselCard>
      <Grid container xs={12} md={12}>
        <Grid item xs={12} md={12} paddingLeft={5}>
          <Typography
            textAlign={'left'}
            fontWeight="500"
            variant="h5"
            lineHeight={'32px'}
            fontFamily={'Inter'}
            mt={2}
          >
            {props.item.review}
          </Typography>
          <Grid container alignSelf={'flex-start'} direction={'row'} mt={4}>
            <Grid
              container
              direction="column"
              m={2}
              sx={{
                maxWidth: { xs: '10rem', sm: '20rem' },
                minHeight: { xs: '2rem', sm: '6rem' },
              }}
            >
              <Typography variant="h5" color={'common.black'}>
                {props.item.reviewBy}
              </Typography>
              <Typography
                variant="h6"
                fontFamily={'Inter'}
                color={'common.black'}
                sx={{ opacity: 0.5 }}
              >
                {props.item.on}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CarouselCard>
  );
}

export default function Testimonials({ props }) {
  return (
    <>
      <HomeComponentContainer>
        <PillWrapper
          gap={2.5}
          textAlign="center"
          label="TESTIMONIALS"
          width="100%"
        >
          <Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Carousel
                navButtonsAlwaysVisible={true}
                indicators={false}
                interval={5000}
                NavButton={({ onClick, next, prev }) => {
                  return (
                    <React.Fragment>
                      {prev && (
                        <NavButton
                          src="/assets/images/leftarrow.svg"
                          width={50}
                          alt={'prev'}
                          height={60}
                          onClick={onClick}
                        />
                      )}
                      {next && (
                        <NavButton
                          src="/assets/images/rightarrow.svg"
                          width={50}
                          alt={'next'}
                          height={60}
                          onClick={onClick}
                        />
                      )}
                    </React.Fragment>
                  );
                }}
                {...props}
              >
                {sliderItems.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Box>

            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <Carousel
                navButtonsAlwaysVisible={false}
                interval={6000}
                {...props}
              >
                {sliderItems.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </Carousel>
            </Box>
          </Box>
        </PillWrapper>
      </HomeComponentContainer>
    </>
  );
}
