import React from "react";
import ThreeColumnComponent from "@components/common/ThreeColumnComponent";
import BackgroundComponentLayout from "./BackgroundComponentLayout";
import HomeComponentContainer from "./HomeComponentContainer";
import PillWrapper from "./PillWrapper";

export default function CustomerResponse() {
  return (
    <>
      <BackgroundComponentLayout
        sx={{
          py: { xs: 6.5, sm: 12.5 },
        }}
      >
        <HomeComponentContainer>
          <PillWrapper gap={6.25} px={{ xs: 2, md: 6 }} label="OUR VALUE">
            <ThreeColumnComponent />
          </PillWrapper>
        </HomeComponentContainer>
      </BackgroundComponentLayout>
    </>
  );
}
