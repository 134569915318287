import { Stack, Box } from "@mui/material";
import PropTypes from "prop-types";
import * as ga from "@lib/ga";
import React from "react";
import TitleBodyPoints from "@components/common/TitleBodyPoints";
import TextImageComponent from "@components/common/TextImageComponent";
import ButtonWithArrow from "@components/common/ButtonWithArrow";
import BackgroundComponentLayout from "./BackgroundComponentLayout";
import PillWrapper from "./PillWrapper";
import HomeComponentContainer from "./HomeComponentContainer";

export default function NatureAtWork({ onButtonClick }) {
  return (
    <BackgroundComponentLayout pt={{ xs: 6, md: 15 }}>
      <HomeComponentContainer>
        <TextImageComponent imageSrc={"/assets/images/hero_image1.png"}>
          <Stack gap={3.75}>
            <PillWrapper gap={2.5} label="NATURE AT WORK">
              <TitleBodyPoints
                title={
                  "Smart office greens for healthier spaces & happier teams."
                }
                highlightText={"healthier spaces & happier teams."}
                body={
                  "Incorporate the critical touch of nature in a data-backed, fully managed AI enabled solutions and services to fortify impact and performance for your workplace."
                }
              />
            </PillWrapper>

            <Box>
              <ButtonWithArrow
                onClick={() => {
                  onButtonClick();
                  ga.event({
                    action: "home - book a demo",
                  });
                }}
              >
                Book a demo
              </ButtonWithArrow>
            </Box>
          </Stack>
        </TextImageComponent>
      </HomeComponentContainer>
    </BackgroundComponentLayout>
  );
}

NatureAtWork.propTypes = {
  onButtonClick: PropTypes.func,
};

NatureAtWork.defaultProps = {
  onButtonClick: () => {},
};
