import React, { useEffect } from "react";
import { Box, Tabs, Tab, Stack } from "@mui/material";
import Image from "next/image";
import { styled } from "@mui/material/styles";

import LinearDeterminate from "./LinearDeterminate";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#B3B3B3",
    "&.Mui-selected": {
      color: "#F44174",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
      margin: "0 0.15rem",
    },
  })
);

const companyData = [
  {
    key: "analysis",
    image: "/assets/images/sparespace.png",
    label: "We analyse your space microclimate",
  },
  {
    key: "solution",
    image: "/assets/images/solution.png",
    label: "Provide tailor made solutions",
  },
  {
    key: "subscription",
    image: "/assets/images/subscription.png",
    label: "With easy subscription plans",
  },
  {
    key: "rewards",
    image: "/assets/images/wellness.png",
    label: "Unlock healthy and happy spaces",
  },
];

export default function AnimationContentComponent() {
  const [value, setValue] = React.useState(0);

  // Set the delay time in milliseconds
  const delay = 2000; // Adjust as needed
  const totalTabs = 4;

  useEffect(() => {
    let currentTab = 0;

    const interval = setInterval(() => {
      setValue(currentTab);
      currentTab++;

      if (currentTab === totalTabs) {
        // clearInterval(interval); // Stop the interval when all tabs are selected
        currentTab = 0;
      }
    }, delay);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [totalTabs, delay]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack
        alignItems="center"
        textAlign="center"
        sx={{
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "70%", md: "100%" },
            overflow: "hidden",
          }}
        >
          <Image
            height={591}
            width={858}
            src={companyData[value].image}
            alt={companyData[value].key}
          />
        </Box>

        <Box
          sx={{
            bgcolor: "background.paper",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
            {companyData.map((item, index) => (
              <StyledTab
                wrapped
                label={
                  <LinearDeterminate
                    delay={delay}
                    active={index <= value}
                    inProgress={index === value}
                  >
                    {item.label}
                  </LinearDeterminate>
                }
                key={item.key}
              />
            ))}
          </StyledTabs>
        </Box>
      </Stack>
    </>
  );
}
