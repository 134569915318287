import PropTypes from "prop-types";
import React from "react";
import { Box, Stack } from "@mui/material";

import TitleBodyPoints from "@components/common/TitleBodyPoints";
import HomeComponentContainer from "./HomeComponentContainer";
import PillWrapper from "./PillWrapper";
import TextImageComponent from "@components/common/TextImageComponent";
import ButtonWithArrow from "@components/common/ButtonWithArrow";
import * as ga from "@lib/ga";
import { useRouter } from "next/router";

export default function OurFeatures() {
  const router = useRouter();
  return (
    <HomeComponentContainer>
      <Stack gap={{ xs: 6, md: 15 }}>
        <PillWrapper
          alignItems="center"
          textAlign="center"
          px={{ xs: 2, md: 6 }}
          label="OUR FEATURES"
        >
          <TitleBodyPoints
            title={"Where technology meets nature"}
            highlightText={"technology"}
            body={
              "We combine millions of data points on plants with the new age AI+IOT technology to analyse your space, design & install the best biophilic setup, care & maintain the greens to unlock wellness for your teams."
            }
          />
        </PillWrapper>
        <Box px={{ xs: 2, md: 6 }}>
          <Stack gap={{ xs: 6, md: 15 }}>
            <TextImageComponent
              imageSrc={"/assets/images/intelligent_greens.png"}
              reverse
            >
              <Stack gap={3.75}>
                <TitleBodyPoints
                  title={"Intelligent greenification"}
                  body={
                    "Our data sets and algorithms design the best biophilic setup that suits your space microclimate & preferences."
                  }
                  points={[
                    "AI models",
                    "10,000+ plant species data",
                    "IOT sensors",
                    "Biophilic frameworks",
                  ]}
                />
                <Box>
                  <ButtonWithArrow
                    onClick={() => {
                      ga.event({
                        action: "home - intelligent greens - platform",
                      });
                      router.push("/platform");
                    }}
                  >
                    Platform
                  </ButtonWithArrow>
                  <ButtonWithArrow
                    variant={"containedPurple"}
                    onClick={() => {
                      ga.event({
                        action: "home - intelligent greens - solutions",
                      });
                      router.push("/solutions");
                    }}
                  >
                    Solutions
                  </ButtonWithArrow>
                </Box>
              </Stack>
            </TextImageComponent>

            <TextImageComponent
              imageSrc={"/assets/images/smart_maintenance.png"}
            >
              <Stack gap={3.75}>
                <TitleBodyPoints
                  title={"Smart maintenance"}
                  body={
                    "We fully manage the care & maintenance of the plants in your space with tech backed optimized schedules."
                  }
                  points={[
                    "24x7 plant doctor on WhatsApp",
                    "On site expert maintenance sessions",
                    "IOT sensors",
                    "Vision + AI technology",
                  ]}
                />
                <Box>
                  <ButtonWithArrow
                    onClick={() => {
                      ga.event({
                        action: "home - maintenance - platform",
                      });
                      router.push("/platform");
                    }}
                  >
                    Platform
                  </ButtonWithArrow>
                  <ButtonWithArrow
                    variant={"containedPurple"}
                    onClick={() => {
                      ga.event({
                        action: "home - maintenance - solutions",
                      });
                      router.push("/solutions");
                    }}
                  >
                    Solutions
                  </ButtonWithArrow>
                </Box>
              </Stack>
            </TextImageComponent>

            <TextImageComponent
              imageSrc={"/assets/images/wellbeing.png"}
              reverse
            >
              <Stack gap={3.75}>
                <TitleBodyPoints
                  title={"Improved well-being"}
                  body={
                    "Our tailor made solutions enhance your space environment & enable your teams to unlock nature for better productivity."
                  }
                  points={[
                    "WELL certification metrics",
                    "IOT sensors",
                    "Kaplan wellness frameworks",
                    "Guidance via WhatsApp",
                  ]}
                />
                <Box>
                  <ButtonWithArrow
                    onClick={() => {
                      ga.event({
                        action: "home - wellbeing - platform",
                      });
                      router.push("/platform");
                    }}
                  >
                    Platform
                  </ButtonWithArrow>
                  <ButtonWithArrow
                    variant={"containedPurple"}
                    onClick={() => {
                      ga.event({
                        action: "home - wellbeing - solutions",
                      });
                      router.push("/solutions");
                    }}
                  >
                    Solutions
                  </ButtonWithArrow>
                </Box>
              </Stack>
            </TextImageComponent>
          </Stack>
        </Box>
      </Stack>
    </HomeComponentContainer>
  );
}

OurFeatures.propTypes = {
  onButtonClick: PropTypes.func,
};

OurFeatures.defaultProps = {
  onButtonClick: () => {},
};
