import Head from "next/head";
import { useRouter } from "next/router";

const Meta = ({
  title = "",
  description = "",
  imageUrl = "",
  type = "website",
  keywords = "Indoor plants for home",
}) => {
  const router = useRouter();
  const canonicalUrl = (
    `https://www.getnaturethings.com` +
    (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" property="og:title" content={title} />
      <meta property="og:site_name" content="Nature Things" />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta
        property="og:image"
        content={"https://www.getnaturethings.com/_next/image?url=" + imageUrl}
      />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:keywords" content={keywords} />

      {/*twitter*/}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content={"summary_large_image"} />

      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default Meta;
