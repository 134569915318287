import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Layout from "@components/common/Layout";
import Meta from "@components/common/Head";
import { Box, Container, Stack } from "@mui/material";
import NatureAtWork from "@components/Home/NewHome/NatureAtWork";
import MarketResponse from "@components/Home/NewHome/MarketResponse";
import CustomerResponse from "@components/Home/NewHome/CustomerResponse";
import OurFeatures from "@components/Home/NewHome/OurFeatures";

import OurCommitment from "@components/Home/NewHome/OurCommitment";
import StartWithSteps from "@components/Home/NewHome/StartWithSteps";
import FreeTrial from "@components/Home/NewHome/FreeTrial";
import ContactUs from "@components/Home/NewHome/ContactUs";
import Testimonials from "@components/PlantBundles/Testimonials";
import { useRef } from "react";

export default function Home() {
  const contactUsRef = useRef();

  const moveToContactUs = () => {
    contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout
      layoutProps={{
        sectionProps: {
          sx: {
            mt: 8.5,
          },
        },
      }}
    >
      <Meta
        title={"Fully managed office greening up solutions."}
        description={
          "naturethings is a tech platform that helps you build healthier spaces and happier teams by enabling biophilic design in your office."
        }
        imageUrl="https://cf-simple-s3-origin-cloudfrontfors3-360504420918.s3.amazonaws.com/banner_v1.png"
      />
      <Container maxWidth="1440px" px={2.5}>
        <Stack gap={{ xs: 6, md: 10 }}>
          <NatureAtWork onButtonClick={moveToContactUs} />

          <MarketResponse />

          <CustomerResponse />

          <StartWithSteps />

          <FreeTrial onButtonClick={moveToContactUs} />

          <OurFeatures />

          <OurCommitment />

          <Testimonials />

          <Box ref={contactUsRef}>
            <ContactUs />
          </Box>
        </Stack>
      </Container>
    </Layout>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "discover"])),
    },
  };
}
