import React from "react";
import { Box, Stack } from "@mui/material";
import TitleBodyPoints from "@components/common/TitleBodyPoints";
import * as ga from "@lib/ga";
import PropTypes from "prop-types";
import ButtonWithArrow from "@components/common/ButtonWithArrow";
import HomeComponentContainer from "./HomeComponentContainer";
import BackgroundComponentLayout from "./BackgroundComponentLayout";
import PillWrapper from "./PillWrapper";

export default function FreeTrial({ onButtonClick }) {
  return (
    <BackgroundComponentLayout
      sx={{
        py: { xs: 6.5, sm: 12.5 },
      }}
    >
      <HomeComponentContainer>
        <Stack gap={6.25} px={{ xs: 2, md: 6 }} alignItems="center">
          <PillWrapper
            gap={2.5}
            alignItems="center"
            textAlign="center"
            label="FREE DEMO"
          >
            <TitleBodyPoints
              title={"Book a Demo today"}
              highlightText={"Demo"}
              body={
                "Foster an impactful workspace by incorporating the critical touch of nature with our fully-managed solution."
              }
              titleProps={{
                sx: {
                  fontSize: { xs: "2rem", md: "3.75rem" },
                },
              }}
            />
          </PillWrapper>

          <Box>
            <ButtonWithArrow
              onClick={() => {
                onButtonClick();
                ga.event({
                  action: "home - book a demo",
                });
              }}
            >
              Book a demo
            </ButtonWithArrow>
          </Box>
        </Stack>
      </HomeComponentContainer>
    </BackgroundComponentLayout>
  );
}

FreeTrial.propTypes = {
  onButtonClick: PropTypes.func,
};

FreeTrial.defaultProps = {
  onButtonClick: () => {},
};
