import React from "react";
import { Stack } from "@mui/material";
import TitleBodyPoints from "@components/common/TitleBodyPoints";
import AnimationContentComponent from "@components/common/AnimationContentComponent";
import HomeComponentContainer from "./HomeComponentContainer";

export default function StartWithSteps() {
  return (
    <HomeComponentContainer>
      <Stack
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        gap={6.25}
        px={{ xs: 2, md: 6 }}
      >
        <TitleBodyPoints
          title={"In just 4 easy steps"}
          highlightText={"4 easy steps"}
          body={
            "Forget the DIY market runs, hauling misfit plants, plant care confidence crisis, stressed out employees."
          }
          titleProps={{
            sx: {
              fontSize: { xs: "2rem", md: "3.75rem" },
            },
          }}
        />

        <AnimationContentComponent />
      </Stack>
    </HomeComponentContainer>
  );
}
