import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";
import { Box, Stack, useMediaQuery } from "@mui/material";
import Image from "next/image";
import React from "react";

export default function TextImageComponent({
  children,
  reverse,
  imageSrc,
  ...rest
}) {
  const { t } = useTranslation(["care", "common"]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={{ xs: "column", md: reverse ? "row-reverse" : "row" }}
      gap={{ xs: 3, md: 12.5 }}
    >
      <Box> {children}</Box>

      <Box sx={{ width: { xs: "100%", sm: "70%", md: "100%" } }}>
        <Image
          layout="responsive"
          width={isMobile ? 343 : 629}
          height={isMobile ? 260 : 532}
          src={imageSrc}
          alt={t("org_care_section_subtitle")}
          {...rest}
        />
      </Box>
    </Stack>
  );
}

TextImageComponent.propTypes = {
  children: PropTypes.any,
  imageSrc: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};
