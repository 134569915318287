import React from "react";
import TabsTopContentComponent from "@components/common/TabsTopContentComponent";
import HomeComponentContainer from "./HomeComponentContainer";
import PillWrapper from "./PillWrapper";
import HighlightedText from "@components/common/HighlightedText";
import { Box, Stack } from "@mui/material";

export default function MarketResponse() {
  return (
    <HomeComponentContainer>
      <PillWrapper
        alignItems="center"
        textAlign="center"
        px={{ xs: 2, md: 6 }}
        label="OUR CUSTOMERS"
      >
        <Stack gap={8.75} width={"100%"}>
          <Box
            sx={{
              px: 2,
            }}
          >
            <HighlightedText
              textAlign={"center"}
              fontWeight="700"
              fontFamily={"Inter"}
              fontSize={{ xs: "20px", md: "40px" }}
              paragraph="Leading workplaces, from co-working to Fortune 500s, are partnering with naturethings to enhance people's well-being and boost productivity."
              highlights={["partnering with naturethings"]}
            />
          </Box>

          <Box>
            <TabsTopContentComponent />
          </Box>
        </Stack>
      </PillWrapper>
    </HomeComponentContainer>
  );
}
