import { Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import React from 'react';

export default function FourCardStatsComponent() {
  const data = [
    {
      key: 1,
      title: '20,000+',
      desc: 'sqft. greenified',
      img: '/assets/images/happy_people.svg',
    },
    {
      key: 2,
      title: '+20%',
      desc: 'boost in employee satisfaction',
      img: '/assets/images/healthy_savings.svg',
    },

    {
      key: 3,
      title: '1000+',
      desc: 'plants cared for',
      img: '/assets/images/better_planet.svg',
    },
    {
      key: 4,
      title: '>10%',
      desc: 'air quality improvement',
      img: '/assets/images/better_planet.svg',
    },
  ];

  return (
    <Grid container spacing={3.75}>
      {data?.map((item) => (
        <Grid item xs={12} md={6} key={item.key}>
          <Stack
            sx={{
              backgroundColor: 'white',
              borderRadius: 2,
              p: 4,
            }}
            key={item.key}
            gap={5}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Stack gap={0.5}>
              <Typography
                fontSize={{ xs: '3rem', md: '4.5rem' }}
                fontWeight={700}
              >
                {item.title}
              </Typography>

              <Typography
                variant="body1"
                color="text.primary"
                fontFamily={'Inter'}
              >
                {item.desc}
              </Typography>
            </Stack>

            <Image height={74} width={74} src={item.img} alt={item.label} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
