import React from "react";
import FourCardStatsComponent from "@components/common/FourCardStatsComponent";
import BackgroundComponentLayout from "./BackgroundComponentLayout";
import HomeComponentContainer from "./HomeComponentContainer";
import PillWrapper from "./PillWrapper";

export default function OurCommitment() {
  return (
    <BackgroundComponentLayout
      sx={{
        py: { xs: 6.5, sm: 12.5 },
      }}
    >
      <HomeComponentContainer>
        <PillWrapper
          gap={6.25}
          px={{ xs: 2, md: 6 }}
          alignItems="center"
          label="OUR IMPACT"
        >
          <FourCardStatsComponent />
        </PillWrapper>
      </HomeComponentContainer>
    </BackgroundComponentLayout>
  );
}
